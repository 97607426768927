import React from 'react';
import Layout from "@components/layout";
import {graphql} from "gatsby";
import SEO from "@components/seo";
import styled from "styled-components";

const MessageLayout = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
  gap: 2rem;
`

const ErrorPage = ({data}) => {
  return <Layout>
    <SEO siteMeta={data.site.siteMetadata}/>
    <MessageLayout href="/">
      <span>Page not found (404)!</span>
      <span>Return to the main page</span>
    </MessageLayout>
  </Layout>
}


export const query = graphql`
    query ErrorPageQuery {
        site {
            siteMetadata {
                title
                description
                siteUrl
                image
                siteLanguage
                siteLocale
                authorName
                favicon
                backgroundColor
                themeColor
                twitterUsername
                googleTag
            }
        }
    }
`

export default ErrorPage;
